<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <apsr2022-about-page-navigation class='flex-shrink-0' />
    <div class='flex-grow relative py-8 lg:py-16 px-4 lg:px-0'>
      <welcome-title title='Committee CCC' />
      <div>
        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic01.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Philip Bardin (Australia)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Editor in Chief of Respirology, Respirology</li>
                <li class='mt-1'>Member, Cenlial Congress Committee</li>
                <li class='mt-1'>Member, Ethics and Disclosure Committee</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic02.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Philip Eng (Singapore)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2021 - <span class='text-gray-400 uppercase'>Present</span></h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Editor in Chief Respiratory Updates, Education Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2021 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Member, Central Congress Committee</li>
                <li class='mt-1'>Chairperson, Education Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2018</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Chairperson, APSR Foundation</li>
                <li class='mt-1'>Chairperson, Central Congress Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2015 - 2018</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Assemblies representative, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2014 - 2017</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Head, Clinical Respiratory Medicine Assembly</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2008 - 2009</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Head, Bronchoscopy and Interventional Techniques Assembly</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0 flex flex-col justify-center items-center gap-y-2'>
            <img src='https://apsr2022.org/img/sub/ccc_pic03.png' alt="">
            <span style="color:#e9425c;font-size:14px; padiving-left:20px ">★ Chairperson</span>
          </div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Masayuki Hanaoka (Japan)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - 2023</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Head, Pulmonary Circulation Assembly</li>
                <li class='mt-1'>Chairperson, Central Congress Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Head-Elect, Pulmonary Circulation Assembly</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2016 - 2017</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Deputy Head, Pulmonary Circulation Assembly</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic04.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Ki-Suck Jung (Korea)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - 2023</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Central Congress Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Congress president, 26th Congress of the APSR (Seoul, Korea)</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2021 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2021 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Congress president-elect, 26th Congress of the APSR (Seoul, Korea)</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2016 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>APSR Councillor, Korea</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2016 - 2019</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Head, Respiratory Infections (non-tuberculous) Assembly</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic05.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Young Whan Kim (Korea)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2019 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Chairperson, Research Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Central Congress Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2018</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Research Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Strategic Planning Committee</li>
                <li class='mt-1'>Member, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2008 - 2016</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>APSR Councillor, Korea</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic06.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>David Chi-Leung Lam (Hong Kong)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - 2023</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Chairperson, Steering Committee</li>
                <li class='mt-1'>Chairperson, Ethics and Disclosure Committee</li>
                <li class='mt-1'>Head-Elect, Lung Cancer Assembly</li>
                <li class='mt-1'>President, 15th president of the APSR</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2021 - <span class='text-gray-400 uppercase'>Present</span></h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Chairperson, Planning and Action Committe</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2021 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>President-Elect</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2020 - 2024</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Member, Strategic Planning Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2020 - 2023</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Research Committee</li>
                <li class='mt-1'>Member, Finance Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2020 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Steering Committee</li>
                <li class='mt-1'>Member, Ethics and Disclosure Committee</li>
                <li class='mt-1'>Deputy Head, Lung Cancer Assembly</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2020 - 2020</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Secretary General</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2019 - 2019</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Education Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>APSR Councillor, Hong Kong</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2013 - 2018</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Chairperson, Education Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2012 - 2018</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2012 - 2012</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Invitee, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2011 - 2018</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Editor in Chief Respiratory Updates, Education Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2009 - 2023</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Central Congress Committee</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic07.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Kazuto Matsunaga (Japan)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Central Congress Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2019 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Chairperson, Central Congress Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Head, COPD Assembly</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2016 - 2017</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Head-Elect, COPD Assembly</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic08.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Yoichi Nakanishi (Japan)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - <span class='text-gray-400 uppercase'>Present</span></h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Nomination Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Immediate past-president</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2020 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Chairperson, Steering Committee</li>
                <li class='mt-1'>Chairperson, Ethics and Disclosure Committee</li>
                <li class='mt-1'>President, 14th president of the APSR</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2019 - 2019</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>President-Elect</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Central Congress Committee</li>
                <li class='mt-1'>Member, Strategic Planning Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Finance Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2019</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Secretary General</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2018</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, APSR Foundation</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - <span class='text-gray-400 uppercase'>Present</span></h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Steering Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2019</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Ethics and Disclosure Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2018</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Central Congress Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2017</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Treasurer, Finance Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2015 - 2016</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>APSR Councillor, Japan</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2009 - 2013</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Assemblies representative, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2008 - 2013</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Head, Lung Cancer Assembly</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic09.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Yasuhiko Nishioka (Japan)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2020 - <span class='text-gray-400 uppercase'>Present</span></h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Education Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2020 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Member, Central Congress Committee</li>
                <li class='mt-1'>Member, Steering Committee</li>
                <li class='mt-1'>Member, Ethics and Disclosure Committee</li>
                <li class='mt-1'>Treasure, Finance Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>APSR Councillor, Japan</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic10.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Paul Nigel Reynolds (Australia)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2017 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Ethics and Disclosure Committee</li>
                <li class='mt-1'>Member, Central Congress Committee</li>
                <li class='mt-1'>Editor in Chief of Respirology, Respirology</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2016 - 2016</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Editor in Chief of Respirology Case Reports, Respirology Case Reports</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2012 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2012 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Councils representative, Executive Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2012 - 2016</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Chairperson, Membership Committee</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2008 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>APSR Councillor, Australia</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2008 - 2008</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Councils representative, Executive Committee</li>
              </ul>
            </div>
          </div>
        </div>

        <div class='flex flex-row items-start gap-x-4 mb-16'>
          <div class='w-24 lg:w-40 flex-shrink-0'><img src='https://apsr2022.org/img/sub/ccc_pic11.png'></div>
          <div>
            <h3 class='font-semibold text-lg text-gray-900'>Chul-Gyu Yoo (Korea)</h3>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - 2023</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Head-Elect, Cell and Molecular Biology Assembly</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2022 - 2022</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Member, Executive Committee</li>
                <li class='mt-1'>Member, Finance Committee</li>
                <li class='mt-1'>Member, Congress Finance Committee</li>
                <li class='mt-1'>Member, Central Congress Committee</li>
                <li class='mt-1'>Member, Ethics and Disclosure Committee</li>
                <li class='mt-1'>Member, Steering Committee</li>
                <li class='mt-1'>Member, Strategic Planning Committee</li>
                <li class='mt-1'>Secretary General</li>
              </ul>
            </div>
            <div class='flex flex-col lg:flex-row mt-2'>
              <h4 class='w-32 lg:w-40 font-medium'>2018 - 2021</h4>
              <ul class='text-sm lg:text-base list-disc list-inside lg:list-none'>
                <li class='mt-1 lg:mt-0'>Deputy Head, Cell and Molecular Biology Assembly</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Apsr2022AboutPageNavigation from '@/components/apsr2022/Apsr2022AboutPageNavigation.vue'
import WelcomeTitle                from '@/components/apsr2022/WelcomeTitle.vue'

export default {
  name: 'CommitteeCcc',
  components: {
    Apsr2022AboutPageNavigation,
    WelcomeTitle,
  },
}
</script>

<style type='scss' scoped>
li {
  text-indent: -20px;
  padding-left: 20px;
}

@media (min-width: 1024px) { 
  li {
    text-indent: 0px;
    padding-left: 0px;
  }
}
</style>
